import { TERM_TYPES } from '@/constants/terms.const';

export const getTermLabel = (key: string): string => {
  switch (key) {
    case TERM_TYPES.LICENSE_AGREEMENT:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_eula';
    case TERM_TYPES.TERMS_OF_SERVICE:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_usage';
    case TERM_TYPES.CHECKOUT_NOTE:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_pre_payment_caution';
    case TERM_TYPES.THIRD_PARTY:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_3rd_party';
    case TERM_TYPES.PROTECTION_AUTOMATIC:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_guardian';
    case TERM_TYPES.PARENTAL_CONSENT:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_guardian_info';
    case TERM_TYPES.PERSONAL_CONSENT:
      return 'studio.prj_prod_mngmt.prod_home.create_term.term_type_personal_info';
    default:
      return '-';
  }
};
